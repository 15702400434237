import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import weddingBg from "../images/wedding-background.jpeg";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  h1,
  h2,
  p {
    margin: 5px 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  max-width: 80%;
`;

const Wedding = () => {
  return (
    <Layout background={weddingBg}>
      <StyledContainer>
        <h1>Thomas and Blue's Wedding</h1>
        <h2>November 6, 2021</h2>
        <ImageContainer>
          <StaticImage
            src="../images/couple.jpg"
            alt="Blue and Thomas"
            placeholder="blurred"
            layout="constrained"
            width={200}
            imgStyle={{ borderRadius: "5px" }}
          />
          <StaticImage
            src="../images/couple2.jpg"
            alt="Blue and Thomas"
            placeholder="blurred"
            layout="constrained"
            width={200}
            imgStyle={{ borderRadius: "5px" }}
          />
          <StaticImage
            src="../images/corner-holding-hands.jpg"
            alt="Blue and Thomas Ring"
            placeholder="blurred"
            layout="constrained"
            width={200}
            imgStyle={{ borderRadius: "5px" }}
          />
          <StaticImage
            src="../images/girls.jpg"
            alt="Blue and Thomas Water"
            placeholder="blurred"
            layout="constrained"
            width={200}
            imgStyle={{ borderRadius: "5px" }}
          />
        </ImageContainer>

      </StyledContainer>
    </Layout>
  );
};

export default Wedding;
